html, body, #root, .app {
  height:100%;
  width: 'auto';
  margin: 0px;
}

#mapview {
  height: 95%;
  width: 'auto';
  margin: 0px;
  overflow: hidden;
}

#mapview-condensed {
  height: 50%;
  width: 'auto';
  margin: 0px;
}

@media only screen and (max-width: 1224px) {
  #electric-charger-detail {
    height: 50%;
  }
  #filters {
    height: 50%;
  }
}